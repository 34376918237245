import { Controller } from "@hotwired/stimulus"
import { Helpers, toggle } from 'pistachio'

export default class extends Controller {
  POLL_TIME_MILLISECONDS = 1000

  static targets = [
    'modal',
    'form',
    'downloadLink',
    'progressBar',
  ]

  static values = {
    'exportUrl': String,
  }

  connect() {
  }

  onExportClick() {
    const path = this.element.dataset.path

    toggle(this.downloadLinkTarget, false)
    this.progressBarTarget.style.width = '0%'
    Helpers.emit(this.modalTarget, 'modal:open!')

    Helpers.fetch('get', this.exportUrlValue).then(response => {
      if(response.ok) {
        response.json.then(json => {
          this.poll(json.status_path)
        })
      }
    })
  }

  poll(statusPath) {
    Helpers.fetch('get', statusPath).then(response => {
      if(response.ok) {
        response.json.then(json => {
          this.progressBarTarget.style.width = `${json.percent_done}%`
          if (json.done) {
            toggle(this.downloadLinkTarget, true)
            this.downloadLinkTarget.href = json.download_path
          } else {
            this.pollingTimeout = setTimeout(() => this.poll(statusPath), this.POLL_TIME_MILLISECONDS)
          }
        })
      }
    })
  }
}
