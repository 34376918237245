import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'checkbox',
    'submit',
  ]

  connect() {
    this.update()
  }

  update() {
    this.submitTarget.disabled = this.checkboxTargets.filter(checkbox => checkbox.checked).length === 0
  }
}
