.c-receivables_form {

        font-size: 0.875rem;

        line-height: 1.25rem
}

  .c-receivables_form__items {

        margin-top: 2rem
}

  .c-receivables_form__items thead tr th,
    .c-receivables_form__items tbody tr td {

        border-bottom-width: 1px;

        padding-top: 0.5rem;

        padding-bottom: 0.5rem
}

  .c-receivables_form__items thead tr th:first-child, .c-receivables_form__items tbody tr td:first-child {

        padding-left: 0.5rem
}

  .c-receivables_form__items thead tr th:last-child, .c-receivables_form__items tbody tr td:last-child {

        padding-right: 0.5rem
}

  .c-receivables_form__items thead tr th:not(:first-child), .c-receivables_form__items tbody tr td:not(:first-child) {

        padding-left: 1.5rem
}

  .c-receivables_form__items thead tr th {

        font-size: 0.875rem;

        line-height: 1.25rem;

        --tw-bg-opacity: 1;

        background-color: rgb(244 244 245 / var(--tw-bg-opacity))
}

  .c-receivables_form__items tbody tr td {

        vertical-align: top;

        font-size: 0.875rem;

        line-height: 1.25rem
}

  .c-receivables_form__items tbody tr td:nth-child(2) {

        width: auto;

        min-width: 200px
}

  .c-receivables_form__items tbody tr td:nth-child(3) input, .c-receivables_form__items tbody tr td:nth-child(4) input {

        text-align: right
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC9jb21wb25lbnRzL3JlY2VpdmFibGVzL2Zvcm1fY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTs7UUFBQSxtQkFBYzs7UUFBZDtBQUFjOztFQUdaOztRQUFBO0FBQVc7O0VBSVQ7OztRQUFBLHdCQUFlOztRQUNmLG1CQUFXOztRQUFYO0FBRGU7O0VBSWI7O1FBQUE7QUFBVzs7RUFJWDs7UUFBQTtBQUFXOztFQUlYOztRQUFBO0FBQVc7O0VBS2I7O1FBQUEsbUJBQWM7O1FBQWQsb0JBQWM7O1FBQ2Qsa0JBQWtCOztRQUFsQjtBQURjOztFQUtkOztRQUFBLG1CQUFnQjs7UUFDaEIsbUJBQWM7O1FBQWQ7QUFEZ0I7O0VBSWQ7O1FBQUEsV0FBYTs7UUFFYjtBQUZhOztFQVFYOztRQUFBO0FBQWlCIiwiZmlsZSI6ImFwcC9jb21wb25lbnRzL3JlY2VpdmFibGVzL2Zvcm1fY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jLXJlY2VpdmFibGVzX2Zvcm0ge1xuICBAYXBwbHkgdGV4dC1zbTtcblxuICAmX19pdGVtcyB7XG4gICAgQGFwcGx5IG10LTg7XG5cbiAgICB0aGVhZCB0ciB0aCxcbiAgICB0Ym9keSB0ciB0ZCB7XG4gICAgICBAYXBwbHkgYm9yZGVyLWI7XG4gICAgICBAYXBwbHkgcHktMjtcblxuICAgICAgJjpmaXJzdC1jaGlsZCB7XG4gICAgICAgIEBhcHBseSBwbC0yO1xuICAgICAgfVxuXG4gICAgICAmOmxhc3QtY2hpbGQge1xuICAgICAgICBAYXBwbHkgcHItMjtcbiAgICAgIH1cblxuICAgICAgJjpub3QoOmZpcnN0LWNoaWxkKSB7XG4gICAgICAgIEBhcHBseSBwbC02O1xuICAgICAgfVxuICAgIH1cblxuICAgIHRoZWFkIHRyIHRoIHtcbiAgICAgIEBhcHBseSB0ZXh0LXNtO1xuICAgICAgQGFwcGx5IGJnLWdyYXktMTAwO1xuICAgIH1cblxuICAgIHRib2R5IHRyIHRkIHtcbiAgICAgIEBhcHBseSBhbGlnbi10b3A7XG4gICAgICBAYXBwbHkgdGV4dC1zbTtcblxuICAgICAgJjpudGgtY2hpbGQoMikge1xuICAgICAgICBAYXBwbHkgdy1hdXRvO1xuXG4gICAgICAgIG1pbi13aWR0aDogMjAwcHg7XG4gICAgICB9XG5cbiAgICAgICY6bnRoLWNoaWxkKDMpLFxuICAgICAgJjpudGgtY2hpbGQoNCkge1xuICAgICAgICBpbnB1dCB7XG4gICAgICAgICAgQGFwcGx5IHRleHQtcmlnaHQ7XG4gICAgICAgIH1cbiAgICAgIH1cbiAgICB9XG4gIH1cbn1cbiJdfQ== */