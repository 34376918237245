import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'button',
    'input',
  ]

  connect() {
    this.value = this.inputTargets.find(input => input.checked).value
    this.update()
  }

  onSelect(event) {
    this.value = event.target.value
    this.update()
  }

  update() {
    this.buttonTargets.forEach(button => {
      button.classList.toggle('active', button.dataset.value == this.value)
    })
  }
}
