import { Helpers, Polyfills } from 'pistachio'
import Glass from 'glass'

import '@hotwired/turbo-rails'
import Appsignal from '@appsignal/javascript'
import { plugin as breadcrumbsNetworkPlugin } from '@appsignal/plugin-breadcrumbs-network'
import { plugin as breadcrumbsConsolePlugin } from '@appsignal/plugin-breadcrumbs-console'
import { plugin as windowEventsPlugin } from `@appsignal/plugin-window-events`

if (process.env.ENABLE_FRONTEND_ERROR_TRACKING == 'true') {
  window.Appsignal = new Appsignal({
    key: /staging/.test(window.location.href) ?  '19cf7f26-36fa-49bc-be3b-76994d85d4ed' : 'a72d2061-952f-47e0-af00-79f3e6e96f62',
    revision: process.env.APP_REVISION,
    ignoreErrors: [
      /XHR request failed \(401\)/,
      /Fetch is aborted/,
      /Not implemented on this platform/, // Likely some Safari extension
    ],
  })

  window.Appsignal.use(breadcrumbsNetworkPlugin({}))
  window.Appsignal.use(breadcrumbsConsolePlugin({}))
  window.Appsignal.use(windowEventsPlugin({}))

  window.Appsignal.addDecorator((span) => {
    return span.setTags({ url: window.location.href });
  })

  // To get a more relevant grouping of errors (as most are just Error),
  // set the error name to <error.name>: <error.message>
  window.Appsignal.addDecorator((span) => {
    const error = span.serialize().error
    return span.setError({
      name: `${error.name}: ${error.message}`,
      message: error.message,
      stack: error.backtrace.join('\n'),
    })
  })
}

Polyfills.checkAndRun(main)

function main(err) {
  require('@rails/ujs').start()
  require('@rails/activestorage').start()

  require('pistachio/app/components')

  require('../component_styles')
  require('../stimulus_controllers')

  document.addEventListener('turbo:load', (event) => {
    Helpers.clarifyEmailAddresses('koleo.ch')

    if (event.detail && event.detail.timing && Object.keys(event.detail.timing).length > 0) {
      Glass.trackXHR(event.detail.timing)
    } else {
      Glass.trackPageLoad()
    }

    if (!Polyfills.browserSupportsAllFeatures()) {
      Glass.track('browser::polyfills_required', Polyfills.featureSupportList())
    }
  })
}


import 'pistachio/app/base'
import '../global.css'

import * as images from '../images/**/*'
images.default.map((image) => image)
