import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['name', 'reason', 'deletionButton', 'deletionLink']

  connect() {
    this.originalLink = this.deletionLinkTarget.href
    this.update()
  }

  onConfirm() {
    this.deletionLinkTarget.click()
  }

  update() {
    const reason = this.reasonTarget.value
    this.deletionLinkTarget.href =  `${this.originalLink}?reason=${encodeURIComponent(reason)}`
  }
}
