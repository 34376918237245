import { Controller } from '@hotwired/stimulus'
import { toggle } from '../../../utils/visibility'
import { Helpers } from '../../../utils/helpers'

import {
  autoUpdate,
  computePosition,
  shift,
  offset,
} from '@floating-ui/dom'

export default class extends Controller {
  static values = {
    placement: String,
  }

  static targets = ['window', 'toggle']

  ACTIVE_CLASS = 'active'

  connect() {
  }

  onDocumentClick(event) {
    if (!this.hasWindowTarget)
      return

    const isClickOnElement = this.element.contains(event.target)
    const isClickOnWindow = this.windowTarget.contains(event.target)

    if (!isClickOnElement && !isClickOnWindow && this.isVisible()) {
      this.show(false)
    }

    // close if we click a link (which is not dynamically handled)
    // this also handles export links in window (which will download a file but not change page, no page replace -> it never closes)
    const isStaticLink = event.target.tagName === 'A' && event.target.href &&
      event.target.href !== '#' && !event.target.href.startsWith('javascript')

    if (isClickOnWindow && isStaticLink) {
      this.show(false)
    }
  }

  toggle() {
    const show = !this.isVisible()
    this.show(show)
  }

  show(show) {
    const changed = (this.isVisible() !== show)

    // position
    if (show) {
      this.cleanupDropdown = autoUpdate(this.toggleTarget, this.windowTarget, () => {
        computePosition(this.toggleTarget, this.windowTarget, {
          placement: this.placementValue,
          middleware: [
            offset(4),
            shift({ padding: 10 }),
          ],
        })
        .then(({x, y, middlewareData, placement}) => {
          Object.assign(this.windowTarget.style, {
            left: `${x}px`,
            top: `${y}px`,
          });

          toggle(this.windowTarget, show)
        })
      })
    }
    else {
      toggle(this.windowTarget, show)
      this.cleanupDropdown()
    }

    if (changed) {
      const eventName = `dropdown:${show ? 'opened' : 'closed'}`
      Helpers.emit(this.element, eventName)
    }

    if (this.hasToggleTarget) {
      const link = this.toggleTarget.querySelector('a, button')
      if (link) link.classList.toggle(this.ACTIVE_CLASS, show)
      const button = this.toggleTarget.querySelector('button')
    }
  }

  isVisible() {
    return this.windowTarget.style.display == 'block'
  }
}
