import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  connect() {
  }

  onMultiToggle() {
    // auto expand textareas if we toggle into edit (in case we have `textarea` fields, i.e. mail message)
    this.element.querySelectorAll('textarea').forEach(textarea => Helpers.autoExpand(textarea))
  }
}
