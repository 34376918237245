table.c-receivables_form_summary {

    table-layout: fixed;

    width: auto;

    margin-top: 1rem
}

  table.c-receivables_form_summary tr td {

    padding-top: 0.25rem;

    padding-bottom: 0.25rem
}

  table.c-receivables_form_summary tr td:first-child {

    text-align: right;

    padding-right: 2rem
}

  table.c-receivables_form_summary tr td:last-child {

    text-align: right;

    font-weight: 600;

    min-width: 6.25rem
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC9jb21wb25lbnRzL3JlY2VpdmFibGVzL2Zvcm0vc3VtbWFyeV9jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFOztJQUFBLG1CQUFrQjs7SUFDbEIsV0FBYTs7SUFDYjtBQUZrQjs7RUFLaEI7O0lBQUEsb0JBQVc7O0lBQVg7QUFBVzs7RUFJWDs7SUFBQSxpQkFBaUI7O0lBQ2pCO0FBRGlCOztFQUtqQjs7SUFBQSxpQkFBaUI7O0lBQ2pCLGdCQUFvQjs7SUFFcEI7QUFIaUIiLCJmaWxlIjoiYXBwL2NvbXBvbmVudHMvcmVjZWl2YWJsZXMvZm9ybS9zdW1tYXJ5X2NvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJ0YWJsZS5jLXJlY2VpdmFibGVzX2Zvcm1fc3VtbWFyeSB7XG4gIEBhcHBseSB0YWJsZS1maXhlZDtcbiAgQGFwcGx5IHctYXV0bztcbiAgQGFwcGx5IG10LTQ7XG5cbiAgdHIgdGQge1xuICAgIEBhcHBseSBweS0xO1xuICB9XG5cbiAgdHIgdGQ6Zmlyc3QtY2hpbGQge1xuICAgIEBhcHBseSB0ZXh0LXJpZ2h0O1xuICAgIEBhcHBseSBwci04O1xuICB9XG5cbiAgdHIgdGQ6bGFzdC1jaGlsZCB7XG4gICAgQGFwcGx5IHRleHQtcmlnaHQ7XG4gICAgQGFwcGx5IGZvbnQtc2VtaWJvbGQ7XG5cbiAgICBtaW4td2lkdGg6IDYuMjVyZW07XG4gIH1cbn1cbiJdfQ== */