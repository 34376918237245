import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'email',
    'isClientEmail',
  ]

  connect() {
    this.update()
  }

  update() {
    const existingEmail = this.emailTarget.dataset.clientEmail
    const showIsClientEmail = !existingEmail || this.emailTarget.value !== existingEmail

    toggle(this.isClientEmailTarget, showIsClientEmail)
  }
}
