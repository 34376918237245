import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle, isVisible } from 'pistachio'

export default class extends Controller {
  static targets = [
    'form',

    'clientNotes',
    'newClient',
    'existingClient',
    'newClientInput',

    'numDueDays',
    'dueDate',
    'dueDateAsDate',
    'dueDateAsDays',

    'templateForm',
    'items',
    'item',
    'itemTemplate',
  ]

  connect() {
    toggle(this.templateFormTarget, false)

    if (!this.newClientInputTarget.value) {
      this.clientMode = 'existing'
    } else {
      this.clientMode = 'new'
    }

    if (this.hasNumDueDaysTarget) {
      if (this.numDueDaysTarget.value) {
        this.dueDateMode = 'days'
      } else {
        this.dueDateMode = 'date'
      }
    }

    this.update()
  }

  update() {
    // New/existing client
    const showExisting = (this.clientMode === 'existing')
    if (this.hasNewClientTarget) {
      Helpers.toggleVisibilityAndState(this.newClientTarget, !showExisting)
      Helpers.toggleVisibilityAndState(this.existingClientTarget, showExisting)
    }

    // Due date as days or date
    if (this.hasNumDueDaysTarget) {
      const showDueDate = (this.dueDateMode === 'date')
      Helpers.toggleVisibilityAndState(this.dueDateAsDateTarget, showDueDate)
      Helpers.toggleVisibilityAndState(this.dueDateAsDaysTarget, !showDueDate)
    }

    // Update calculation
    const url = this.element.dataset.calculateUrl
    const params = Helpers.getFormDataForXHR(this.formTarget)

    Helpers.fetchAbortPrevious(this, 'put', url, { body: params }).then(response => {
      if(response.ok) {
        response.html.then(html => {
          var parser = new DOMParser()
          var doc = parser.parseFromString(html, 'text/html')

          // Summary
          let selector = '[data-calculation="summary"]'
          let src = doc.querySelector(selector)
          let dst = this.element.querySelector(selector)

          dst.innerHTML = src.innerHTML

          // Invoice Items
          selector = '[data-calculation="item-amount"]'
          src = doc.querySelectorAll(selector)
          dst = this.element.querySelectorAll(selector)

          let srcIndex = 0
          let srcElement
          dst.forEach(dstElement => {
            if (!isVisible(dstElement)) {
              // marked as destroy
              return
            }

            if(srcElement = src[srcIndex]) {
              dstElement.innerHTML = srcElement.innerHTML
              srcIndex++
            }
          })
        })
      }
    })
  }

  onNewPosition(event) {
    this.createNewPosition()
    this.update()
  }

  onNewProduct(event) {
    if (!event.detail) return

    const product = event.detail.data

    // if last position blank -> use it for this product, otherwise create new position
    let position

    if (this.itemTargets.length > 0) {
      const lastPosition = this.itemTargets[this.itemTargets.length - 1]

      let isBlank = true
      isBlank &&= isVisible(lastPosition) // must be visible (in case it was deleted, don't use this pos)
      isBlank &&= lastPosition.querySelector('[name*="description"]').value == ''
      isBlank &&= lastPosition.querySelector('[name*="unit_price"]').value == '0.0'

      if (isBlank) {
        position = lastPosition
      }
    }

    position ||= this.createNewPosition()

    // description
    const descriptionField = position.querySelector('[name*="description"]')
    descriptionField.value = product.name
    if (product.description && product.description != '') {
      descriptionField.value += `\n${product.description}`
    }
    Helpers.autoExpand(descriptionField)

    // price
    const unitPriceField = position.querySelector('[name*="unit_price"]')
    unitPriceField.value = product.price

    // vat
    const vatPercentField = position.querySelector('[name*="vat_percent"]') // either select or hidden
    vatPercentField.value = product.vat_percent
    Helpers.emit(vatPercentField, 'select:update!')

    // product id
    const productIdField = position.querySelector('[name*="product_id"]')
    productIdField.value = product.id

    this.itemsTarget.append(position)
    this.update()
  }

  onPositionUp(event) {
    const row = event.target.closest('tr')

    // find previous VISIBLE row (rows can be hidden if removed via trash icon)
    let prevRow = row.previousElementSibling
    while (prevRow && !isVisible(prevRow)) {
      prevRow = prevRow.previousElementSibling
    }

    const parent = row.parentNode

    if (row && prevRow) {
      parent.insertBefore(row, prevRow)
    }
  }

  onPositionDown(event) {
    const row = event.target.closest('tr')

    // find next VISIBLE row (rows can be hidden if removed via trash icon)
    let nextRow = row.nextElementSibling
    while (nextRow && !isVisible(nextRow)) {
      nextRow = nextRow.nextElementSibling
    }

    const parent = row.parentNode

    if (row && nextRow) {
      parent.insertBefore(nextRow, row)
    }
  }

  onPositionRemove(event) {
    const row = event.target.closest('tr')
    const destroy = row.querySelector('input[name*="_destroy"]')
    destroy.value = '1'
    toggle(row, false)
    this.update()
  }

  onFormChanged() {
    this.update()
  }

  onDueDateAsDate() {
    this.dueDateMode = 'date'
    this.update()
  }

  onDueDateAsDays() {
    this.dueDateMode = 'days'
    this.update()

    this.numDueDaysTarget.focus()
  }

  onClientSet(event) {
    if (event.detail) {
      this.clientNotesTarget.innerText = event.detail.notes
    } else {
      this.clientNotesTarget.innerText = ''
    }

    toggle(this.clientNotesTarget, !!this.clientNotesTarget.innerText)
  }

  onNewClient() {
    this.clientMode = 'new'
    this.update()

    this.newClientInputTarget.focus()
  }

  onExistingClient() {
    this.clientMode = 'existing'
    this.update()
  }

  // private

  createNewPosition() {
    const newIndex = this.itemTargets.length

    const newItem = Helpers.clone(this.itemTemplateTarget)
    newItem.setAttribute('data-receivables--form-target', 'item')

    newItem.querySelectorAll('[name*="["]')
      .forEach(el => el.name = el.name.replace(/\[\d+\]/, `[${newIndex}]`))

    // make sure input ids are not duplicated (problems with labels & checkboxes otherwise)
    newItem.querySelectorAll('[id]').forEach(element => {
      const oldId = element.id
      const newId = `${oldId}_${newIndex}`

      element.id = newId
      newItem.querySelectorAll(`label[for='${oldId}']`).forEach(label => label.setAttribute('for', newId))
    })

    this.itemsTarget.append(newItem)
    return newItem
  }
}
