import { Controller } from '@hotwired/stimulus'
import { Helpers } from '../../../utils/helpers'

export default class extends Controller {
  static values = {
    src: String,
    loading: String,
  }

  connect() {
    if (this.loadingValue === 'lazy') {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.loadContent()
            this.observer.unobserve(entry.target)
          }
        })
      })

      this.observer.observe(this.element)
    } else {
      this.loadContent()
    }
  }

  disconnect() {
    this.observer?.disconnect()
  }

  loadContent() {
    this.element.setAttribute('busy', '')

    // Response handled by turbo
    Helpers.fetch('get', this.srcValue, { responseKind: 'turbo-stream' })
      .then(response => this.element.removeAttribute('busy'))
  }
}
