import { Controller } from '@hotwired/stimulus'
import { toggle, isHidden } from 'pistachio'

export default class extends Controller {
  static targets = [
    'mobileNavigationClosed',
    'mobileNavigationOpen',
    'mobileNavigation',

    'searchInput',
  ]

  update() {
    let showMobileNavigation = false

    if(isHidden(this.mobileNavigationTarget)) {
      showMobileNavigation = true
    }

    toggle(this.mobileNavigationClosedTarget, !showMobileNavigation)
    toggle(this.mobileNavigationOpenTarget, showMobileNavigation)
    toggle(this.mobileNavigationTarget, showMobileNavigation)
  }

  onSearchInputFocus(event) {
    this.searchInputTarget.select()
  }

  onWindowInputKeyup(event) {
    const e = event
    if (e.key !== "/" || e.ctrlKey || e.metaKey) return
    if (/^(?:input|textarea|select|button)$/i.test(e.target.tagName)) return
    this.searchInputTarget.focus()
  }

  toggleMobileNavigation() {
    this.update()
  }
}
