import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'template',
  ]

  onResetTemplate(event) {
    this.templateTargets.forEach((template) => {
      if (template.dataset.type == event.target.dataset.type) {
        template.value = template.dataset.default
        Helpers.autoExpand(template)
      }
    })
  }
}
