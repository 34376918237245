import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'offersEnabled',
    'offersFields',

    'creditsEnabled',
    'creditsFields',
  ]

  connect() {
    this.update()
  }

  update() {
    toggle(this.offersFieldsTarget, this.offersEnabledTarget.checked)
    toggle(this.creditsFieldsTarget, this.creditsEnabledTarget.checked)
  }
}
