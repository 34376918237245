import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'subscriptionInfo',
  ]

  connect() {
    Helpers.fetch('get', this.subscriptionInfoTarget.dataset.url).then(response => {
      if(response.ok) {
        response.html.then(html => {
          this.subscriptionInfoTarget.innerHTML = html
        })
      }
    })
  }
}
