import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['uploadButton']

  onFileSelected() {
    this.uploadButtonTarget.disabled = false
    this.uploadButtonTarget.classList.remove('disabled')
  }
}
