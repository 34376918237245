.c-button-group-select > label:first-of-type > .c-button {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px
}
  .c-button-group-select > label:last-of-type > .c-button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px
}
  .c-button-group-select > label:not(:first-of-type):not(:last-of-type) > .c-button {
    border-radius: 0px
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3Bpc3RhY2hpby9hcHAvY29tcG9uZW50cy9waXN0YWNoaW8vYnV0dG9uX2dyb3VwX3NlbGVjdF9jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVJO0lBQUEsNEJBQXNCO0lBQ3RCO0FBRHNCO0VBS3RCO0lBQUEsMkJBQXNCO0lBQ3RCO0FBRHNCO0VBS3RCO0lBQUE7QUFBbUIiLCJmaWxlIjoiLi4vcGlzdGFjaGlvL2FwcC9jb21wb25lbnRzL3Bpc3RhY2hpby9idXR0b25fZ3JvdXBfc2VsZWN0X2NvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYy1idXR0b24tZ3JvdXAtc2VsZWN0IHtcbiAgPiBsYWJlbDpmaXJzdC1vZi10eXBlID4gLmMtYnV0dG9uIHtcbiAgICBAYXBwbHkgcm91bmRlZC10ci1ub25lO1xuICAgIEBhcHBseSByb3VuZGVkLWJyLW5vbmU7XG4gIH1cblxuICA+IGxhYmVsOmxhc3Qtb2YtdHlwZSA+IC5jLWJ1dHRvbiB7XG4gICAgQGFwcGx5IHJvdW5kZWQtdGwtbm9uZTtcbiAgICBAYXBwbHkgcm91bmRlZC1ibC1ub25lO1xuICB9XG5cbiAgPiBsYWJlbDpub3QoOmZpcnN0LW9mLXR5cGUpOm5vdCg6bGFzdC1vZi10eXBlKSA+IC5jLWJ1dHRvbiB7XG4gICAgQGFwcGx5IHJvdW5kZWQtbm9uZTtcbiAgfVxufVxuIl19 */